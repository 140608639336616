import { loadYoutubeApi } from '../../utilities/js/helper';

class YoutubePlayer {

    constructor(element, options) {

        const settings = {
            showInfo: 0,
            rel: 0,
            color: 'white',
            autoplay: true,
            height: 720,
            width: 1280,
            onStart: null,
            onResume: null,
            onEnd: null,
            onPause: null,
            titleTargetAttr: 'data-youtube-titletarget'
        };

        this.settings = Object.assign({}, settings, options);
        this.$element = element;
        this.player = [];
    }

    initialize() {
        this.startPlayer();
    }

    startPlayer () {
        const youtubeId = this.$element.getAttribute('data-youtube');
        this.youtubeId = youtubeId;
        const _window = window;
        let firststart = true;

        const buildPlayer = (videoId) => {
            const $oldPlayer = this.$element.querySelectorAll('iframe');

            if($oldPlayer.length === 1) {

                $oldPlayer.parentNode.removeChild($oldPlayer);
            }

            let $player = document.createElement('div');

            $player.setAttribute('id', _uniqueID());
            $player.className = 'player';

            let $parent = this.$element.parentNode;

            $parent.insertBefore($player, this.$element.nextSibling);

            this.playerId = $player.id;

            this.player.playing = false;
            this.player.video = new YT.Player($player.id, {
                height: this.settings.height,
                host: 'https://www.youtube-nocookie.com',
                width: this.settings.width,
                videoId: videoId,
                playerVars: {
                    'showinfo': this.settings.showinfo,
                    'rel': this.settings.rel,
                    'color': this.settings.color,
                    'fs': 1,
                    'playsinline': 0
                },
                events: {
                    onReady: () => {
                        if(document.querySelector('html').classList.contains('no-csshover')) {

                            this.$element.classList.remove('available');
                            this.player.video.cueVideoById(videoId, 1, 'large');
                            this.player.video.playVideo();
                        } else {
                            this.player.video.playVideo();
                        }
                    },
                    onStateChange: (event) => {

                        if(event.data === 1) { // start video

                            if(this.player.playing === false) {

                                this.$element.classList.add('playing');

                                if(typeof _window.videoplayer !== 'undefined' && _window.videoplayer.playing === true) {

                                    _window.videoplayer.video.pauseVideo();
                                    _window.videoplayer.video.playing = false;
                                }
                                this.player.playing = true;
                            }

                            if(firststart === true) {
                                firststart = false;

                                if(typeof this.settings.onStart === 'function') {
                                    this.settings.onStart(videoId);
                                }
                            } else {
                                if(typeof this.settings.onResume === 'function') {
                                    this.settings.onResume(videoId);
                                }
                            }

                            _window.videoplayer = this.player;
                        } else if(event.data === 2) { // pause video

                            this.player.playing = false;

                            if(typeof this.settings.onPause === 'function') {
                                this.settings.onPause(videoId);
                            }
                        } else if(event.data === 0) { // video ended

                            this.player.playing = false;
                            this.$element.classList.remove('playing');

                            if(typeof this.settings.onEnd === 'function') {
                                this.settings.onEnd(videoId);
                            }
                        }
                    }
                }
            });

        };

        this.$element.classList.add('available');

        this.$element.addEventListener('click', () => {
            buildPlayer(youtubeId);
        });

        const titleTarget = this.$element.getAttribute(this.settings.titleTargetAttr);
        if (titleTarget !== null) {
            const $titleTarget = document.getElementById(titleTarget);
            if ($titleTarget) {
                this.getYoutubeInfo((info) => {
                    this.setTitle(info, $titleTarget);
                });
            }
        }
    }

    play() {

        if(this.player.playing === false) {
            this.player.video.playVideo();
        }
    }

    pause() {

        if(this.player.playing === true) {
            this.player.video.pauseVideo();
        }
    }

    destroy() {
        this.player.playing = false;
        this.$element.classList.remove('available');
        this.$element.classList.remove('playing');

        const $player = document.getElementById(this.playerId);
        if($player) {
            $player.parentNode.removeChild($player);
        }
    }

    getYoutubeInfo (callback) {
        const url = 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + this.youtubeId + '&fields=items(id%2Csnippet)&key=' + googleApiKey;
        const request = new XMLHttpRequest();

        request.open('GET', url, true);
        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Success!
                const data = JSON.parse(request.responseText);
                if (typeof callback === 'function') {
                    callback(data.items[0].snippet);
                }
            } else {
                console.error('api could not be loaded');
                callback('');
            }
        };
        request.send();
    }

    setTitle (info, $target) {
        if (info !== '') {
            $target.innerHTML = info.title;
        } else {
            $target.parentNode.removeChild($target);
        }
    }
}

export { YoutubePlayer };


window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    const toggleCookieMessage = ($$element, show) => {
        $$element.forEach($element => {
            const $videoElement = $element.closest('[data-media="youtube"]');
            if (show) {
                $videoElement.querySelector('.media__restriction').classList.add('is--shown');
                $videoElement.classList.add('has--restriction');
            } else {
                $videoElement.querySelector('.media__restriction').classList.remove('is--shown');
                $videoElement.classList.remove('has--restriction');
            }
        });
    };

    if ($context) {
        const $$video = $context.querySelectorAll('[data-youtube]');

        const initMedia = () => {
            toggleCookieMessage($$video, false);
            $$video.forEach($video => {
                const videoAPI = new YoutubePlayer($video);
                videoAPI.initialize();
                $video.videoAPI = videoAPI;
            });
        };

        if (window.cookieConsentInitialized) {
            toggleCookieMessage($$video, true);
        }

        const waitForConsent = setInterval(() => {
            if (window.cookieConsentInitialized) {
                if (CookieConsent.consent.marketing === true) {
                    clearInterval(waitForConsent);

                    if (typeof window.YT === 'undefined') {
                        console.log('YT not defined, wait for iframe api');
                        loadYoutubeApi();

                        window.onYouTubeIframeAPIReady = () => {
                            console.log('api ready, init media');
                            initMedia();
                        };
                    } else {
                        console.log('api already loaded, init media');
                        initMedia();
                    }
                }

                window.addEventListener('CookiebotOnDecline', () => {
                    console.log('decline');
                    if (CookieConsent.consent.marketing === false) {
                        toggleCookieMessage($$video, true);

                        $$video.forEach($video => {
                            const videoAPI = $video.videoAPI;
                            videoAPI.destroy();
                        });
                    }
                });
            }
        }, 300);
    }
});
